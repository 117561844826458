$color-description:white;


.Project{
    box-shadow: 1px 1px 2px 1px rgba(0,0,0,.2);
    width: 150px;
    min-width: 150px;
    height: 230px;
    margin-right: 40px;
    margin-left: 60px;
    cursor: pointer;
    overflow: hidden;

&__top{
    background-image: url('../images/hero-bg.jpg');
    background-size: cover;
    height: 55%;
    width: 100%;
    box-sizing: border-box;
}
&__image{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
&__description{
    color: $color-description;
    text-align: right;
    margin-top: -21%;
    height: 15%;
    width: 100%;
    background-color: rgba(0,0,0,.4);
    transform: translateX(-100%);
    padding: 7px 10px 3px 10px;
    
    
}

&__tags{
    height: 45%;

}
@keyframes appearDescription {
    0%{
        
    }
    100%{
        transform: translateX(-10%);
    }
    
}
}
.Project:hover .Project__description{
    animation: appearDescription 1s 1 forwards;
}

.Project:hover{
    cursor: pointer;
    transform: scale(105%);
}
