.Post{
    width: 175px;
    height: 225px;
    margin: 0;
    padding:0;
    overflow: hidden;
    position: relative;
    background-color: blue;

    &__imageContainer{
        background-color: black;
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0px;
        z-index: 100;
    }
    &__image{
        width: 100%;
        height: 225px;
        object-fit: cover;

    }
    &__description{
        overflow: hidden;
        color: white;
        background-image: linear-gradient(180deg,#6995ff,#3dbbd3);
        box-sizing: border-box;
        font-size: .8rem;
        text-align: center;
        padding: 8px 2px;
        margin: 0;
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0px;
        z-index: 500;
    }
}