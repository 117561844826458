.New{
    display: flex;
    width: 100%;
    height: 250px;
    box-sizing: border-box;
    padding: 0px 25px;
    justify-content: center;
    align-items: center;

    &__imageContainer{
        width: 20%;
        height: 200px;
        background-color: black;
        overflow: hidden;
        margin-right: 80px;
    }
    &__image{

    }
    &__description{
        width: 20%;
        text-align: center;
    }
}