
$color-hero: white;

.Hero{
    box-sizing: border-box;
    height:100vh;
    width:100%;
    overflow: hidden;
    background-image:url('../images/hero-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    color: $color-hero;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__greeting{
        font-size: 1.2rem;
        margin-bottom: 0;
        font-weight: 500;
        font-style: italic;
    }
    &__name{
        margin-top: 0;
        font-size: 1.8rem;
        font-weight: 700;
        
    }
}   