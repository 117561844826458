$btn-color: #3cbbd3;
$text-btn-color: white;
.News{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    &__title{
        text-align: center;
        padding: 0;
        margin: 0;
    }
    &__button{
        margin-top: 25px;
        background-color: $btn-color;
        border: none;
        width: 150px;
        height: 30px;
        color: $text-btn-color;

    }
}