.Contact{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    &__email{
        border: solid 1px #3dbbd3;
        color: gray;
        width: 210px;
        height: 24px;
        margin-bottom: 25px;
    }
    &__email::placeholder{
        color: rgba(#c5adad,0.3);
    }
    &__email:focus{
        outline: none !important;
        box-shadow: 0px 0px 1px 1px #3dbbd3;
    }
    &__message{
        border: solid 1px #3dbbd3;
        color: gray;
        width: 340px;
        height: 150px;
        margin-bottom: 25px;
        
    }
    &__message::placeholder{
        color: rgba(#000000,0.3);
        vertical-align: top;
    }
    &__message:focus{
        outline: none !important;
        box-shadow: 0px 0px 1px 1px #3dbbd3;
    }
    &__button{
        border: none;
        width: 95px;
        height: 27px;
        background-color: #3dbbd3;
        color: white;
    }
    &__button:hover{
        cursor: pointer;
    }
}