.Ngo{

    &__grid{
        display: grid;
        grid-template-columns: repeat(7,1fr);
        grid-template-rows: repeat(8,1fr);
        width: 600px;
        height: 400px;

    }
    &__item{
        border: solid 1px #121212;
        overflow: hidden;
        
    }
    &__item:hover{
        cursor: pointer;
        filter: saturate(200%) hue-rotate(2deg);
    }
    &__image{
        object-fit: contain;
        max-width: 100%;
        min-height: 100%;
    }
    &__itemOne{
        grid-column: 1 / 4;
        grid-row: 1/ 6;
        background-color: #ededed;
        border-right: none;
        border-bottom: none;

    }
    &__itemTwo{
        grid-column: 4 / 6;
        grid-row: 1/ 5;
        background-color: #0291dc;
        border-right: none;
        border-bottom: none;
    }
    &__itemThree{
        grid-column: 6 / 8;
        grid-row: 1/ 4;
        padding: 10px 10px;
        border-bottom: none;

    }
    &__itemFour{
        grid-column: 1 / 4;
        grid-row: 6/ 9;
        border-right: none;
    }
    &__itemFour:hover{
        filter: drop-shadow(0px 0px 1px black);
    }
    &__itemFive{
        grid-column: 4 / 6;
        grid-row: 5 / 9;
        border-right: none;

    }
    &__itemSix{
        box-sizing: border-box;
        grid-column: 6 / 8;
        grid-row: 4/ 9;
        padding: 10px 10px;

    }

}
