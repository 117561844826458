.Projects{
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    overflow: hidden;
    // overflow:hidden;
    position: relative;
    // display: grid;
    // grid-template-columns: 1fr 30fr 1fr;
    align-content: center;

    &__content{
        padding-top: 35px;
        padding-bottom: 35px;
        box-sizing: content-box;
        display: flex;
        align-content: center;
        justify-items: right;
        overflow:hidden;

    }
    &__items{
        box-sizing: content-box;
        width: 100%;
        
        // display: grid;
        // grid-template-columns: repeat(4,1fr);
        // column-gap: 100px;
        // grid-auto-flow: column;
        // justify-content: space-around;
        // align-items: center;
        display: flex;
        transition-timing-function: ease-in-out;
        transition-property: transform;
        transition-duration: 1s;
    }
    &__arrow{
        position: absolute;
        top: 0;
        display: block;
        font-size: 2em;
        background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(255, 255, 255));
        height: 100%;
        width: 55px;
        color: gray;
        text-align: center;
        line-height: 270px;
        user-select: none;
        z-index: 1000;
    }
    &__arrow:hover{
        cursor: pointer;
    }
    .Projects__arrow--right{
        background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(255, 255, 255));
        position: absolute;
        right: 0;
        justify-self: right;
    }
    .Projects__arrow--right:hover{
        background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(63, 172, 235));
    }

    .Projects__arrow--left:hover{
        background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(63, 172, 235));
    }

}
