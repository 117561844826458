.tagContainer{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 15px 10px;

}

.tag__element{
    box-sizing: border-box;
    display: block;
    height: 25px;
    border-radius: 15px;
    border: solid 1px black;
    padding: 5px 9px ;
    font-size: .8rem;
    line-height: .8rem;
}
