.ProfileContainer{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 25px 40px;
    background-color: #ebebeb;
}

.Profile__personal,.Profiles__professional{
}
.Profile__item{
    height: 70px;
    max-width: 300px;
    padding:10px 20px;
    text-align: center;
}