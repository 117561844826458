$bgc-navbar:rgba(#ababab,0.2);

.Navbar{
    height: 60px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding:0px 45px;
    position:fixed;
    font-weight:600;

    color: white;
    background-color:$bgc-navbar;

    &__logo{
        width: 45px;
        height: 45px;
        display: block;
    }
    &__options{
        display: block;
        width: 500px;
    }
    &__list{
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__item{
        display: block;
        a{
            color:white;
            text-decoration: none;
        }
    }
    &__item:hover{
        cursor:pointer;
    }
}
